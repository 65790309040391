import axios from "axios";
import { toast } from "react-toastify";

const UploadWidget = ({ name, email, setUploadData, uploadData }) => {
  async function visaFilesUploadNotification(fileCount) {
    try {
      const response = await axios.post(
        `https://accounts.myadmissionlink.com/api/update/slack/status`,
        {
          text: `Visa files have been uploaded by
          Name: ${name}
          Email: ${email}
          No of files: ${fileCount}
          Date: ${new Date().toLocaleString("en-us", {
            hour12: true,
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}`,
        }
      );
      toast.info("Uploading process complete", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (err) {
      toast.error(
        "Upload complete but there was error in notifying admin. Kindly contact support.",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  }

  let uploadedFilesCount = 0;
  let totalFilesCount = 0;

  function uploadFiles(e) {
    e.preventDefault();

    const id = toast.loading("Loading, please wait...");
    if (name === "" || email === "") {
      toast.update(id, {
        render: "All fields are required",
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
      return;
    }

    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: "myadmissionlink-educational-consult",
        uploadPreset: "sdzighve",
        folder: `visa-files/${name}`,
        resourceType: "auto",
        multiple: true,
        showUploadMoreButton: true,
        showAdvancedOptions: false,
        showPoweredBy: false,
        preBatch: (cb, data) => {
          totalFilesCount = data?.files.length;
          cb();
        },
      },
      function (error, result) {
        if (result?.info === "shown") {
          toast.dismiss();
        }

        if (result?.event === "close") {
          setUploadData({ ...uploadData, name: "", email: "" });
        }

        if (!error && result && result.event === "success") {
          // update the number of uploaded file
          uploadedFilesCount++;

          // if total number of files is equal to the total number of updated file, run the notification function
          if (totalFilesCount === uploadedFilesCount) {
            toast.update(id, {
              render: `You have successfully uploaded ${uploadedFilesCount} files. Notifying admin...`,
              type: "success",
              isLoading: false,
              autoClose: 2000,
            });
            visaFilesUploadNotification(uploadedFilesCount);
          }
        } else if (error) {
          toast.update(id, {
            render: `Something went wrong!!`,
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });
        }
      }
    );

    widget.open();
  }

  return (
    <button className="upload-btn" type="button" onClick={uploadFiles}>
      Upload files
    </button>
  );
};

export default UploadWidget;
