import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "./assets/images/logo.png";
import UploadWidget from "./components/UploadWidget";
// import { useState } from "react";
// import {openUploadWidget} from 'cloudinary-react/lib/utils/cloudinary'

function App() {
  const [uploadData, setUploadData] = useState({
    name: "",
    email: "",
  });
  const [uploadStatus, setUploadStatus] = useState({
    success: false,
    message: ``,
  });

  useEffect(() => {
    if (uploadStatus.success) {
      console.log(`Upload Successful, Admin has been notified`);
    }
  }, [uploadStatus]);

  return (
    <>
      <ToastContainer />
      <div className="main-page">
        <div className="container">
          <div className="header-section">
            <img src={Logo} alt="A1tutor Logo" />
            <h3>Visa Document Upload</h3>
          </div>

          <div className="body-section">
            <div className="body-section-info">
              {/* <h6>
              Please fill all input field in the form. You can upload multiple
              files
            </h6> */}
            </div>

            <div className="body-section-form">
              <form>
                <div className="mb-3 body-section-form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    value={uploadData?.name}
                    placeholder="Enter your full name"
                    onChange={(e) =>
                      setUploadData({ ...uploadData, name: e.target.value })
                    }
                  />
                </div>

                <div className="mb-3 body-section-form-group">
                  <label>Email Address</label>
                  <input
                    type="email"
                    value={uploadData?.email}
                    placeholder="Enter an email address"
                    onChange={(e) =>
                      setUploadData({ ...uploadData, email: e.target.value })
                    }
                  />
                </div>

                <div className="body-section-form-group">
                  <label>Upload files</label>
                  <UploadWidget
                    name={uploadData?.name}
                    email={uploadData?.email}
                    setUploadStatus={setUploadStatus}
                    uploadStatus={uploadStatus}
                    setUploadData={setUploadData}
                    uploadData={uploadData}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
